// react
import { useEffect, useState } from 'react';

// next
import { useRouter } from 'next/router';

// packages
import { styled } from '@mui/material/styles';

// redux
import { useGetAffiliateByIdQuery } from 'src/store/slices/affiliates';
import { useGetClientByIdQuery } from 'src/store/slices/clients';
import { useGetNotificationByIdQuery } from 'src/store/slices/notifications';
import { useGetUserByIdQuery } from 'src/store/slices/users';

// components
import NextBreadcrumbs, {
  BreadcrumbsProps,
} from 'src/components/BaseBreadcrumbs';

const StyledNav = styled('div')(({ theme }) => ({
  nav: {
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
    ol: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      li: {
        textTransform: 'capitalize',
        '&:last-child': {
          '&::after': {
            display: 'none',
          },
        },
        '&::after': {
          content: '"/"',
          userSelect: 'none',
          marginLeft: 8,
          marginRight: 8,
        },
        a: {
          color: theme.palette.text.secondary,
          textDecoration: 'none',
        },
      },
    },
  },
}));

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const [resolvedIds, setResolvedIds] = useState<Record<string, string>>({});
  const router = useRouter();

  const urlSegments = router.pathname.slice(1).split('/');
  const resolvedUrlSegment = router.asPath.slice(1).split('/');

  const { data: affiliateData, isLoading: affiliateDataIsLoading } =
    useGetAffiliateByIdQuery(
      {
        id: router.query?.affiliate_id as string,
      },
      {
        skip: !router.query?.affiliate_id,
      }
    );

  const { data: clientData, isLoading: clientDataIsLoading } =
    useGetClientByIdQuery(
      {
        id: router.query?.client_id as string,
      },
      { skip: !router.query?.client_id }
    );

  const { data: userData, isLoading: userDataIsLoading } = useGetUserByIdQuery(
    {
      id: router.query?.user_id as string,
    },
    {
      skip: !router.query?.user_id,
    }
  );

  const { data: notificationData, isLoading: notificationDataIsLoading } =
    useGetNotificationByIdQuery(
      {
        id: router.query.notification_id as string,
      },
      {
        skip: !router.query.notification_id,
      }
    );

  useEffect(() => {
    if (affiliateData && !resolvedIds[affiliateData.id]) {
      setResolvedIds((state) => ({
        ...state,
        [affiliateData.id]: affiliateData.name,
      }));
    }

    if (clientData && !resolvedIds[clientData.id]) {
      setResolvedIds((state) => ({
        ...state,
        [clientData.id]: clientData.name,
      }));
    }

    if (userData && !resolvedIds[userData.id]) {
      setResolvedIds((state) => ({
        ...state,
        [userData.id]:
          userData.first_name && userData.last_name
            ? `${userData.first_name} ${userData.last_name}`
            : userData.email,
      }));
    }

    if (
      notificationData &&
      notificationData?.id &&
      !resolvedIds[notificationData.id]
    ) {
      setResolvedIds((state) => ({
        ...state,
        [notificationData.id as string]: notificationData.name,
      }));
    }
  }, [affiliateData, clientData, userData, notificationData, resolvedIds]);

  const hiddenBreadcrumb =
    urlSegments.length === 1 ||
    (urlSegments.length === 2 && urlSegments.includes('orders')); // don't display if we are on a top level route

  const removeProductIndexAndQueryParams = () => {
    const queryParamIndex = resolvedUrlSegment[
      resolvedUrlSegment.length - 1
    ].startsWith('?')
      ? [resolvedUrlSegment.length - 1]
      : [];
    if (!urlSegments.includes('[product]')) return [...queryParamIndex];

    return [
      urlSegments.findIndex((s) => s === '[product]'),
      ...queryParamIndex,
    ];
  };

  if (
    affiliateDataIsLoading ||
    clientDataIsLoading ||
    userDataIsLoading ||
    notificationDataIsLoading
  )
    return <>Loading...</>;

  return !hiddenBreadcrumb ? (
    <StyledNav>
      <NextBreadcrumbs
        omitRootLabel
        omitIndexList={removeProductIndexAndQueryParams()}
        transformLabel={(label) =>
          resolvedIds[label] ? resolvedIds[label] : label
        }
        {...props}
      />
    </StyledNav>
  ) : null;
};

export default Breadcrumbs;
