// redux
import { store } from 'src/store';
import { pushMessageAlert } from 'src/store/slices/alert';

// utils
import capitalize from 'lodash/capitalize';

// types
import { AxiosError, AxiosInstance } from 'axios';

export type ErrorData = {
  originalMessage: string;
  detail: string | ErrorDetails[];
  error: {
    message: string;
    errors: { message: string }[];
  };
};

export type ErrorDetails = {
  loc: string[];
  msg: string;
  type: string;
};

export type ErrorMessage = {
  msg: string;
  type: string;
};

type FormError = {
  loc: string[];
  msg: string;
  type: string;
};

type Query = {
  q: string;
};

type Sort = {
  sort: string;
};

type ValidationError = {
  type: string;
} & (Query | Sort);

type ErrorResponse = ErrorData | FormError[] | ValidationError[] | null;

export const sessionExpiredError =
  'Your user is not allowed to execute this operation, or your session has expired.';

export const errorInterceptor = (
  error: AxiosError,
  dispatch: typeof store.dispatch
) => {
  // For normal API responses assign here
  const errorStatus = error.response?.status;
  let pushMessage = error.response?.statusText || '';

  if (!errorStatus) return dispatchErrorMessage(dispatch, 'Not found', error);

  if (errorStatus === 500)
    return dispatchErrorMessage(dispatch, 'An error has occurred.', error);

  if (
    (errorStatus === 401 &&
      error.request.responseURL ===
        `${process.env.NEXT_PUBLIC_API_BASE_URL}v4/auth/me`) ||
    (error.request.responseURL ===
      `${process.env.NEXT_PUBLIC_API_BASE_URL}v4/auth/login` &&
      // eslint-disable-next-line lines-around-comment
      // @ts-expect-error unknown error type with accessing value on response body
      error.response?.data?.['2fa'])
  )
    return Promise.reject(error);

  if (isSessionExpired(errorStatus, error))
    return dispatchErrorMessage(dispatch, sessionExpiredError, error);

  // Respond to 400-level errors
  if (errorStatus >= 400 && errorStatus < 500) {
    if (errorStatus === 422) {
      const errors = error.response?.data as ValidationError[];
      const errorMessage = extractQueryParamsErrorMessage(errors);
      if (errorMessage) {
        dispatch(pushMessageAlert({ message: errorMessage }));

        return Promise.resolve({ data: { items: [] } });
      }
    }

    const responseObj = error.response?.data as ErrorResponse;
    if (responseObj instanceof Array) {
      responseObj.forEach((err: any) => {
        pushMessage = `${capitalize(err['loc'][err['loc'].length - 1])}: ${
          err['msg']
        }`;
      });

      return dispatchErrorMessage(dispatch, pushMessage, error);
    }

    if (responseObj?.detail) {
      if (typeof responseObj.detail === 'string') {
        pushMessage = responseObj.detail;
      } else {
        const [topError] = responseObj.detail;
        pushMessage = `${topError.loc[topError.loc.length - 1]}: ${
          topError.msg
        }`;
      }
    } else if (responseObj?.error?.message) {
      pushMessage = responseObj?.error?.message;
    } else if (
      (responseObj as unknown as ErrorMessage)?.type === 'Bad Request' &&
      (responseObj as unknown as ErrorMessage)?.msg
    ) {
      pushMessage = (responseObj as unknown as ErrorMessage)?.msg;
    } else {
      pushMessage = 'Not found';
    }
  }

  return dispatchErrorMessage(dispatch, pushMessage, error);
};

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
  const { dispatch } = store;

  const filterErrorInterceptorByStatusCodes = (
    error: AxiosError,
    errorCodes: [number]
  ) => {
    const errorStatus = error.response?.status;

    if (errorCodes.includes(errorStatus || -1)) {
      return Promise.reject(error);
    }

    return errorInterceptor(error, dispatch);
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    (response) => filterErrorInterceptorByStatusCodes(response, [404])
  );
};

const exceptionRoutes = [
  `${process.env.NEXT_PUBLIC_API_BASE_URL}v4/auth/login`,
  `${process.env.NEXT_PUBLIC_API_BASE_URL}v4/auth/logout`,
];

const isSessionExpired = (
  errorStatus: number | undefined,
  error: AxiosError<unknown, any>
) =>
  errorStatus === 401 && !exceptionRoutes.includes(error.request.responseURL);

const dispatchErrorMessage = (
  dispatch: any,
  pushMessage: string,
  error: AxiosError<unknown, any>
) => {
  dispatch(pushMessageAlert({ message: pushMessage }));

  return Promise.reject(error);
};

const extractQueryParamsErrorMessage = (errors: ValidationError[]) => {
  if (!Array.isArray(errors)) return null;

  if (errors.length === 0) return null;

  const error = errors[0];
  if (error.hasOwnProperty('q')) {
    const err = error as Query;

    return err.q;
  }

  if (error.hasOwnProperty('sort')) {
    const err = error as Sort;

    return err.sort;
  }

  return null;
};
