// ** React Import
import { ReactNode } from 'react';

// ** Next Import
import Link from 'next/link';

// ** MUI Imports
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// ** Icons
import CloseIcon from '@mui/icons-material/Close';

// ** Components
import CollapsedNavigationButton from './CollapsedNavButton';

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext';
import ProtechtLogo from 'src/components/ProtectLogo';

interface Props {
  hidden: boolean;
  navHover: boolean;
  settings: Settings;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
  verticalNavMenuBranding?: (props?: any) => ReactNode;
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: theme.spacing(4),
  justifyContent: 'space-between',
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight,
}));

const StyledLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    verticalNavMenuBranding: userVerticalNavMenuBranding,
  } = props;

  // ** Hooks & Vars
  const { navCollapsed } = settings;

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userVerticalNavMenuBranding) {
        return 0;
      }

      return (collapsedNavWidth - navigationBorderWidth - 40) / 8;
    }

    return 5.5;
  };

  return (
    <MenuHeaderWrapper
      className='nav-header'
      sx={{ pl: menuHeaderPaddingLeft() }}
    >
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <Link href='/dashboard/' passHref legacyBehavior>
          <StyledLink>
            {!(navCollapsed && !navHover) ? (
              <ProtechtLogo width='140' />
            ) : (
              <ProtechtLogo width='25' small />
            )}
          </StyledLink>
        </Link>
      )}

      {hidden ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={toggleNavVisibility}
          sx={{ p: 0, backgroundColor: 'transparent !important' }}
          data-testid='app-bar-button-close'
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      ) : (
        <CollapsedNavigationButton {...props} disableCollapse />
      )}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;
