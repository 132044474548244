// packages
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

// utils
import { setToDate } from 'src/@core/utils/date';

// types
import type { VerticalNavItemsType } from 'src/@core/layouts/types';
import type { LoggedInUserType } from 'src/context/types';

const setDefaultFiltersOnLoad = () => {
  return `&date=Last30&date_to=${setToDate()}`;
};

const navigation = (user?: LoggedInUserType | null): VerticalNavItemsType => {
  const defaultFilters = setDefaultFiltersOnLoad();

  return [
    {
      title: 'Affiliates',
      icon: GroupsIcon,
      path: '/affiliates/?pages=0',
      action: 'view',
      subject: 'affiliates',
    },
    {
      title: 'Clients',
      icon: PeopleIcon,
      path:
        user?.role?.name === 'CLIENT'
          ? `/clients/${user.client_id}/`
          : '/clients/?pages=0',
      action: 'view',
      subject: 'clients',
    },
    {
      icon: ReceiptLongIcon,
      title: 'Orders',
      action: 'view',
      subject: 'orders',
      path: `/orders/?pages=0${defaultFilters}`,
    },
    {
      title: 'Policies',
      icon: DescriptionIcon,
      path: `/policies/?pages=0${defaultFilters}`,
      action: 'view',
      subject: 'policies',
    },
    {
      title: 'Claims',
      icon: StickyNote2OutlinedIcon,
      path: `/claims/?pages=0${defaultFilters}`,
      action: 'view',
      subject: 'claims',
    },
    {
      title: 'Users',
      icon: PersonSearchIcon,
      path: '/users/?pages=0',
      action: 'view',
      subject: 'users',
    },
    {
      title: 'Notifications',
      icon: NotificationsIcon,
      path: '/notifications/?pages=0',
      action: 'view',
      subject: 'notifications',
    },
  ];
};

export default navigation;
