// packages
import { DateTime } from 'luxon';

// types
import type { FilterNames } from 'src/context/types';

export type DashboardDateFilterOptionsType = {
  value: DateOptionsType;
  label: string;
};

export type DateOptionsType =
  | 'separator'
  | 'LTEGTE'
  | 'Today'
  | 'Yesterday'
  | 'Last7'
  | 'Last30'
  | 'MonthToDate'
  | 'YearToDate';

export type FilterDataTypeOut = { [key in FilterNames]: string } & {
  date_to?: string;
  date_from?: string;
};

export const format = "yyyy-LL-dd'T'TT";

export const getPreviousDay = (date = new Date()) => {
  date.setDate(date.getDate() - 1);

  return new Date(date).toDateString();
};

export const isDefaultDate = (date: string) =>
  isToday(date) || isYesterday(date);

export const isToday = (date: string) =>
  date === 'Last30' ||
  date === 'Last7' ||
  date === 'Today' ||
  date === 'YearToDate' ||
  date === 'MonthToDate';

export const isYesterday = (date: string) => date === 'Yesterday';

export const isBetween = (date: string) => date === 'LTEGTE';

export const extractLuxonDateTime = (date: string) => {
  const dateTime = new Date(date);

  return DateTime.fromJSDate(dateTime);
};

export const formatJSDate = (date: string) =>
  DateTime.fromJSDate(new Date(date)).toFormat('MM/dd/yyyy');

export const formatLuxonDate = (luxonDateTime: DateTime) => {
  // Convert the date to the user's local timezone
  const localDateTime = luxonDateTime.setZone('local');

  // Convert the local date back to UTC
  const utcDateTime = localDateTime.setZone('utc');

  return utcDateTime.toISODate();
};

export const formatLocalDateTimeToUtcIso = (datetime: Date) => {
  const luxonDateTime = DateTime.fromJSDate(datetime);

  // Convert the local date to UTC
  const utcDateTime = luxonDateTime.setZone('utc');

  return utcDateTime.toISO();
};

const subtractDays = (luxonDateTime: DateTime, days: number) =>
  luxonDateTime.minus({ days: days });

export const addDays = (luxonDateTime: DateTime, days: number) =>
  luxonDateTime.plus({ days: days });

const setFirstMonthOfYear = (firstDay: DateTime) => firstDay.set({ month: 1 });

const setFirstDayOfMonth = (luxonDateTime: DateTime) =>
  luxonDateTime.set({ day: 1 });

export const formatDateForSubmit = (date: string | undefined) => {
  if (!date) return '';
  const luxonDateTime = extractLuxonDateTime(date);

  return formatLuxonDate(luxonDateTime);
};

export const getFirstDateOfMonth = (date: string) => {
  const luxonDateTime = extractLuxonDateTime(date);
  const firstDay = setFirstDayOfMonth(luxonDateTime);
  const formattedDate = formatLuxonDate(firstDay);

  return formattedDate;
};

export const getFirstDateOfYear = (date: string) => {
  const luxonDateTime = extractLuxonDateTime(date);
  const firstDay = setFirstDayOfMonth(luxonDateTime);
  const firstMonth = setFirstMonthOfYear(firstDay);
  const formattedDate = formatLuxonDate(firstMonth);

  return formattedDate;
};

export const getDateRangeFrom = (date: string, days: number) => {
  const luxonDateTime = extractLuxonDateTime(date);
  const luxonDateTimeRange = subtractDays(luxonDateTime, days);
  const formattedDate = formatLuxonDate(luxonDateTimeRange);

  return formattedDate;
};

export const formatDateTime = (date: string | undefined | null) => {
  if (!date) return '';

  return formatUTCDateTime(date);
};

export const formatUTCDateTime = (date: string) => {
  const utcDate = DateTime.fromISO(date, { zone: 'utc' });
  const formattedDate = utcDate.toFormat("MM/dd/yyyy hh:mm a 'UTC'");

  return formattedDate;
};

export const setFromDate = (value?: string) => {
  try {
    if (value) return zeroFromDate(value);

    return DateTime.fromJSDate(new Date())
      .minus({ year: 2 })
      .plus({ days: 2 })
      .set({ hour: 0, millisecond: 0, minute: 0, second: 0 })
      .toFormat(format);
  } catch (error) {
    console.error(error);

    return '';
  }
};

export const setToDate = (value?: string) => {
  try {
    return DateTime.fromJSDate(value ? new Date(value) : new Date())
      .set({ hour: 11, millisecond: 59, minute: 59, second: 59 })
      .toFormat(format);
  } catch (error) {
    console.error(error);

    return '';
  }
};

export const zeroFromDate = (value?: string) => {
  try {
    return DateTime.fromJSDate(value ? new Date(value) : new Date())
      .set({ hour: 0, millisecond: 0, minute: 0, second: 0 })
      .toFormat(format);
  } catch (error) {
    console.error(error);

    return '';
  }
};

export const dashboardDateFilterOptions: DashboardDateFilterOptionsType[] = [
  {
    value: 'Today',
    label: 'Today',
  },
  {
    value: 'Yesterday',
    label: 'Yesterday',
  },
  {
    value: 'Last7',
    label: 'Last 7 Days',
  },
  {
    value: 'Last30',
    label: 'Last 30 Days',
  },
  {
    value: 'MonthToDate',
    label: 'Month to Date',
  },
  {
    value: 'YearToDate',
    label: 'Year to Date',
  },
];
