// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosInstance, createAxiosBaseQuery } from './baseAPI';
import { setupInterceptors } from './setupInterceptors';
import { alertSlice } from './slices/alert';

export const coreApi = createApi({
  tagTypes: [
    'users',
    'roles',
    'clients',
    'affiliates',
    'orders',
    'policies',
    'products',
    'notifications',
    'tokenKeys',
    'claims',
    'sub-affiliates',
  ],
  baseQuery: createAxiosBaseQuery(),
  endpoints: () => ({}),
});

export const store = configureStore({
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
    [alertSlice.name]: alertSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([coreApi.middleware]),
});

setupInterceptors(axiosInstance);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
