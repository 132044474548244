import Script from 'next/script';
import { FC, useEffect, useRef, useState } from 'react';
import { LayoutProps } from 'src/@core/layouts/types';
import { LoggedInUserType } from 'src/context/types';
import { useAuth } from 'src/hooks/useAuth';
import { useGetUserByIdQuery } from 'src/store/slices/users';
import HorizontalLayout from './HorizontalLayout';
import VerticalLayout from './VerticalLayout';

const GoogleTagManagerLayout: FC<{
  child: JSX.Element;
  user: LoggedInUserType | null
}> = ({ child, user }) => {

  const [affiliate_name, setAffiliate_name] = useState('');
  const [client_name, setClient_name] = useState('');

  const { data: userData } = useGetUserByIdQuery(
    {
      id: user?.id as string,
    },
    {
      skip: user?.superuser,
    }
  );

  useEffect(() => {
    if (userData) {
      if (userData.affiliate?.name) {
        setAffiliate_name(userData.affiliate.name);
      }
      if (userData.client?.name) {
        setClient_name(userData.client?.name);
      }
    }
  }, [userData]);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG_ID}`}
        strategy='afterInteractive'
      />
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG_ID}', {
          'user_id': '${user?.id || 'superuser'}',
          'client_name': '${client_name}',
          'affiliate_name': '${affiliate_name}'
        });
      `}
      </Script>
      {child}
    </>
  );
};

const Layout = (props: LayoutProps) => {
  const { hidden, children, settings, saveSettings } = props;
  const isCollapsed = useRef(settings.navCollapsed);
  const { user } = useAuth();

  useEffect(() => {
    if (hidden) {
      if (settings.navCollapsed) {
        saveSettings({ ...settings, navCollapsed: false, layout: 'vertical' });
        isCollapsed.current = true;
      } else {
        saveSettings({ ...settings, layout: 'vertical' });
      }
    } else if (isCollapsed.current) {
      saveSettings({
        ...settings,
        navCollapsed: true,
        layout: settings.lastLayout,
      });
      isCollapsed.current = false;
    } else {
      saveSettings({ ...settings, layout: settings.lastLayout });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  return (
    <GoogleTagManagerLayout
      user={user}
      child={
        settings.layout === 'horizontal' ? (
          <HorizontalLayout {...props}>{children}</HorizontalLayout>
        ) : (
          <VerticalLayout {...props}>{children}</VerticalLayout>
        )
      }
    />
  );
};

export default Layout;
