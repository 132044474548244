// react
import { ReactNode, ReactElement, useEffect } from 'react';

// next
import { useRouter } from 'next/router';

// hooks
import { useAuth } from 'src/hooks/useAuth';

// utils
import authConfig from 'src/configs/auth';
import { axiosInstance } from 'src/store/baseAPI';

interface AuthGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const AuthGuard = (props: AuthGuardProps) => {
  // destructure
  const { children, fallback } = props;

  // hooks
  const { isPasswordOtp, loading, setHasAccessToken, user } = useAuth();
  const router = useRouter();

  useEffect(() => {
    const checkAccessToken = async () => {
      try {
        if (loading || !router.isReady) return;

        const meRes = await axiosInstance.get(authConfig.meEndpoint);

        if (meRes.status !== 200) throw meRes.data;

        setHasAccessToken(true);
      } catch (error) {
        setHasAccessToken(false);
      }
    };

    checkAccessToken();
  }, [loading, router.isReady, router.pathname, setHasAccessToken]);

  useEffect(() => {
    if (!router.isReady || loading) return;

    if (
      isPasswordOtp &&
      !sessionStorage.getItem(authConfig.storageTempUserDataKeyName) &&
      !sessionStorage.getItem(
        authConfig.storageTempResetPasswordUserDataKeyName
      )
    ) {
      router.replace('/reset-password/');

      return;
    }

    if (
      user === null &&
      !localStorage.getItem(authConfig.storageUserDataKeyName)
    ) {
      if (router.asPath !== '/') {
        router.replace(
          {
            pathname: '/login/',
            query: { returnUrl: router.asPath },
          },
          '/login/'
        );
      } else {
        router.replace('/login/');
      }
    }
  }, [isPasswordOtp, loading, user, router]);

  if (loading || user === null) return fallback;

  return <>{children}</>;
};

export default AuthGuard;
