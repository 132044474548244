// react
import { useEffect } from 'react';

// next
import Link from 'next/link';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { AlertState, clearMessages } from 'src/store/slices/alert';
import { selectAlert } from 'src/store/slices/alert/selectors';

// packages
import Alert from '@mui/material/Alert';
import MuiLink from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

const MESSAGE_DISPLAY_TIMEOUT_MS = 5000;

const AppSnackbar = () => {
  const dispatch = useDispatch();
  const { alert }: AlertState = useSelector(selectAlert);

  useEffect(() => {
    if (!alert) return;

    const timeout = setTimeout(() => {
      dispatch(clearMessages());
    }, alert?.timeout ?? MESSAGE_DISPLAY_TIMEOUT_MS);

    return () => clearTimeout(timeout);
  }, [alert, dispatch]);

  useEffect(() => {
    return () => {
      // when unmounting, clear messages
      dispatch(clearMessages());
    };
  }, [dispatch]);

  if (!alert) return null;

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      open={true}
    >
      <Alert
        variant='filled'
        elevation={3}
        onClose={() => {
          if (alert?.onCloseCb) alert?.onCloseCb();
          dispatch(clearMessages());
        }}
        severity={alert?.type}
        sx={{
          '& .MuiAlert-message': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          },
        }}
      >
        {alert?.message}
        {alert?.actionMessage && alert?.actionLink && (
          <Link passHref href={alert.actionLink} legacyBehavior>
            <Typography
              component={MuiLink}
              onClick={() => dispatch(clearMessages())}
              sx={{
                ml: 1,
                fontSize: '0.85rem',
                textDecoration: 'underline',
              }}
            >
              <span>{alert?.actionMessage}</span>
            </Typography>
          </Link>
        )}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
