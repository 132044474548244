import React, { FC } from 'react';

// types
import type { CSSProperties } from 'react';

type Logo = {
  width?: string;
  small?: boolean;
  style?: CSSProperties;
};

const ProtectLogo: FC<Logo> = ({
  width = '100%',
  small = false,
  style = {},
}) => {
  const imgUrl = small
    ? '/images/protecht_cut_blk.png'
    : '/images/protecht_horizontal_blk.png';

  return <img style={style} src={imgUrl} alt='Protecht Logo' width={width} />;
};

export default ProtectLogo;
