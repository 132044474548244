// react
import { ReactNode, ReactElement, useEffect } from 'react';

// next
import { useRouter } from 'next/router';

// hooks
import { useAuth } from 'src/hooks/useAuth';

// config
import authConfig from 'src/configs/auth';

interface GuestGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children, fallback } = props;
  const {
    hasAccessToken,
    isPasswordOtp,
    isPasswordExpiringWarning,
    loading,
    user,
  } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (
      loading ||
      !router.isReady ||
      isPasswordOtp ||
      isPasswordExpiringWarning
    )
      return;

    // user is authed and should be redirected away from guest routes
    if (
      hasAccessToken &&
      localStorage.getItem(authConfig.storageUserDataKeyName)
    )
      router.replace('/dashboard/');

    if (sessionStorage.getItem(authConfig.storageTempUserDataKeyName))
      router.replace('/activate/');

    if (sessionStorage.getItem(authConfig.storageTempUserDataKeyName))
      router.replace('/forgot-password/activate/');
  }, [
    hasAccessToken,
    isPasswordExpiringWarning,
    isPasswordOtp,
    loading,
    router,
    user,
  ]);

  if (loading) return fallback;

  return <>{children}</>;
};

export default GuestGuard;
