// ** Type Imports
import { PaletteMode } from '@mui/material';
import { Skin } from 'src/@core/layouts/types';

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
  // ** Vars
  const lightColor = '76, 78, 100';
  const darkColor = '234, 234, 255';
  const mainColor = mode === 'light' ? lightColor : darkColor;

  const defaultBgColor = () => {
    if (skin === 'bordered' && mode === 'light') {
      return '#FFF';
    }
    if (skin === 'bordered' && mode === 'dark') {
      return '#30334E';
    }
    if (mode === 'light') {
      return '#F7F7F9';
    }

    return '#303135'; // protecht dark gray
  };

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: '#333333',
      lightBg: '#F7F7F9',
      bodyBg: mode === 'light' ? '#F7F7F9' : '#282A42', // Same as palette.background.default but doesn't consider bordered skin
      tooltipBg: mode === 'light' ? '#262732' : '#464A65',
      tableHeaderBg: mode === 'light' ? '#F5F5F7' : '#3A3E5B',
    },
    common: {
      black: '#000',
      white: '#FFF',
    },
    mode,
    primary: {
      light: '#5C7799', // protecht primary blue // dark blue
      main: '#283a55',
      dark: '#1D2D49',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#D1B480', // protecht gold
      main: '#b38e57',
      dark: '#99713F',
      contrastText: '#FFF',
    },
    success: {
      light: '#55A249',
      main: '#1F641B', // green
      dark: '#135615',
      contrastText: '#FFF',
    },
    error: {
      light: '#FF625F',
      main: '#FF4D49', // red
      dark: '#E04440',
      contrastText: '#FFF',
    },
    warning: {
      light: '#FDBE42',
      main: '#FDB528', // yellow
      dark: '#DF9F23',
      contrastText: '#FFF',
    },
    info: {
      light: '#40CDFA',
      main: '#26C6F9', // light blue
      dark: '#21AEDB',
      contrastText: '#FFF',
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030',
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.68)`,
      disabled: `rgba(${mainColor}, 0.38)`,
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? '#FFF' : '#555555',
      default: defaultBgColor(),
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.05)`,
      hoverOpacity: 0.05,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`,
    },
  };
};

export default DefaultPalette;
