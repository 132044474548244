export const decodeJWTPayload = (token?: string | null) => {
  try {
    if (!token) return null;

    const base64 = token.split('.')[1].replace('-', '+').replace('_', '/');

    if (!window) return JSON.parse(Buffer.from(base64, 'base64').toString());

    return JSON.parse(
      decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      )
    );
  } catch (error) {
    return null;
  }
};
