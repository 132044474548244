import {
  NotificationDataType,
  NotificationDeleteDataType,
  NotificationDeleteMutationDataType,
  NotificationInsertMutationDataType,
  NotificationMutationDataType,
} from 'src/context/types';
import { coreApi } from 'src/store';

// https://redux-toolkit.js.org/rtk-query/overview
export const notificationApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getNotifications: build.query<
      { items: NotificationDataType[] },
      {
        page: number;
        pageSize: number;
        sortModel: { field?: string; sort?: string };
        searchTerm?: string;
        affiliate?: string;
        client?: string;
        name?: string;
        email?: string;
      }
    >({
      query: ({
        email,
        name,
        page,
        pageSize,
        sortModel,
        searchTerm,
        affiliate,
        client,
      }) => {
        return {
          url: '/nota/subscriptions',
          method: 'get',
          params: {
            limit: pageSize,
            offset: page * pageSize,
            sort: sortModel.sort
              ? `${sortModel.sort === 'desc' ? '-' : ''}${sortModel.field}`
              : undefined,
            affiliate: affiliate || undefined,
            client: client || undefined,
            name: name || undefined,
            email: email || undefined,
            q: searchTerm || undefined,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(
                ({ id }) => ({ type: 'notifications', id } as const)
              ),
              { type: 'notifications', id: 'LIST' },
            ]
          : [{ type: 'notifications', id: 'LIST' }],
    }),
    getNotificationById: build.query<NotificationDataType, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/nota/subscriptions/${id}`,
          method: 'get',
        };
      },
      providesTags: (result, error, { id }) => [{ type: 'notifications', id }],
    }),
    updateNotification: build.mutation<
      NotificationDataType,
      Partial<NotificationMutationDataType>
    >({
      query: ({ id, ...data }) => ({
        url: `/nota/subscriptions/${id}`,
        method: 'post',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'notifications', id: 'LIST' },
              { type: 'notifications', id: result.id },
            ]
          : [],
    }),
    createNotification: build.mutation<
      NotificationDataType,
      Partial<NotificationInsertMutationDataType>
    >({
      query: (notificationData) => ({
        url: `/nota/subscriptions`,
        method: 'post',
        data: notificationData,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'notifications', id: 'LIST' }] : [],
    }),
    deleteNotification: build.mutation<
      NotificationDeleteDataType,
      NotificationDeleteMutationDataType
    >({
      query: (id) => ({
        url: `/nota/subscriptions/${id}`,
        method: 'delete',
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'notifications', id: 'LIST' }] : [],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useUpdateNotificationMutation,
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
} = notificationApi;
