// ** React Imports
import { useState, SyntheticEvent } from 'react';

// ** Next Import
import { useRouter } from 'next/router';

// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';

// ** Icons Imports
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

// ** Context
import { useAuth } from 'src/hooks/useAuth';

// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext';

interface Props {
  settings: Settings;
}

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // ** Hooks
  const router = useRouter();
  const { logout, user } = useAuth();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url);
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary',
    },
  };

  const handleLogout = () => {
    logout();
    handleDropdownClose();
  };

  return (
    <>
      <Avatar
        alt='user'
        onClick={handleDropdownOpen}
        sx={{ width: 40, height: 40 }}
        data-testid='app-bar-user-dropdown'
      >
        <PersonOutlineIcon />
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
      >
        {!user?.superuser && (
          <MenuItem
            sx={{ p: 0 }}
            onClick={() => handleDropdownClose(`/users/${user?.id}`)}
            data-testid='app-bar-user-dropdown-option'
          >
            <Box sx={styles}>
              <PersonOutlineIcon sx={{ marginRight: 2 }} />
              Profile
            </Box>
          </MenuItem>
        )}
        {!user?.superuser && <Divider />}
        <MenuItem
          sx={{ py: 2 }}
          onClick={handleLogout}
          data-testid='app-bar-user-dropdown-option'
        >
          <LogoutIcon
            sx={{
              marginRight: 2,
              fontSize: '1.375rem',
              color: 'text.secondary',
            }}
          />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;
