// utils
import { apiVersion } from 'src/configs/globalVariable';

export default {
  activateEndpoint: (id: string, token: string) =>
    `/${apiVersion}/auth/activate?id=${id}&token=${token}`,
  extendAccessToken: `/${apiVersion}/auth/refresh`,
  forgotEndpoint: `/${apiVersion}/auth/forgot-password`,
  loginEndpoint: `/${apiVersion}/auth/login`,
  logoutEndpoint: `/${apiVersion}/auth/logout`,
  meEndpoint: `/${apiVersion}/auth/me`,
  resendMfaTokenEndpoint: `/${apiVersion}/auth/resend_token`,
  resetEndpoint: `/${apiVersion}/auth/change-password`,
  resetPasswordAlertKeyName: 'resetPasswordAlert',
  storageTempUserDataKeyName: 'tempUserData',
  storageTempResetPasswordUserDataKeyName: 'tempResetPasswordUserData',
  storageUserDataKeyName: 'userData',
  submitMfaTokenEndpoint: `/${apiVersion}/auth/authentication_token`,
};
