// next
import Link from 'next/link';

// packages
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

// components
import ProtechtLogo from 'src/components/ProtectLogo';
import UserDropdown from 'src/layouts/components/UserDropdown';

// types
import type { Settings } from 'src/@core/context/settingsContext';

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // destructure
  const { hidden, settings, toggleNavVisibility } = props;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      data-testid='app-bar'
    >
      <Box
        className='actions-left'
        sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
      >
        {hidden ? (
          <IconButton
            color='inherit'
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
            data-testid='app-bar-button-open'
          >
            <MenuIcon />
          </IconButton>
        ) : null}
      </Box>
      {hidden && (
        <Link href='/dashboard/' legacyBehavior>
          <Box sx={{ cursor: 'pointer' }}>
            <ProtechtLogo width='140' />
          </Box>
        </Link>
      )}
      <Box
        className='actions-right'
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};

export default AppBarContent;
