import {
  ClientDataType,
  ClientMutationDataType,
  UserMutationDataType,
  AuthTokenType,
  PrimaryPolicyDataType,
  UserDataType,
  UnionOverrideKeys,
  PWCUrl,
} from 'src/context/types';
import { coreApi } from 'src/store';

// https://redux-toolkit.js.org/rtk-query/overview
export const clientsApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getClients: build.query<
      { items: ClientDataType[] },
      {
        affiliate?: string;
        client?: string;
        page?: number;
        pageSize?: number;
        sortModel?: { field?: string; sort?: string };
        searchTerm?: string;
      }
    >({
      query: ({ affiliate, client, pageSize, page, sortModel, searchTerm }) => {
        const parameters = {
          offset: (page ?? 0) * (pageSize ?? 0),
          sort: sortModel?.sort
            ? `${sortModel.sort === 'desc' ? '-' : ''}${sortModel.field}`
            : undefined,
          q: searchTerm || undefined,
          affiliate,
          client,
        } as any;
        if (parameters && pageSize !== 0) parameters.limit = pageSize;

        return {
          url: `/clients`,
          method: 'get',
          ...{
            params: parameters,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(
                ({ id }) => ({ type: 'clients', id } as const)
              ),
              { type: 'clients', id: 'LIST' },
            ]
          : [],
    }),
    getClientById: build.query<ClientDataType, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/clients/${id}`,
          method: 'get',
        };
      },
      providesTags: (result, error, { id }) => [{ type: 'clients', id }],
    }),
    createClient: build.mutation<
      ClientDataType,
      UnionOverrideKeys<ClientMutationDataType, { products: string[] }>
    >({
      query: (data) => ({
        url: `/clients`,
        method: 'post',
        data: data,
      }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'clients', id: 'LIST' }] : [];
      },
    }),
    createClientUser: build.mutation<UserDataType, UserMutationDataType>({
      query: ({ id, ...userData }) => ({
        url: `/clients/${id}/create-user`,
        method: 'post',
        data: userData,
      }),
      invalidatesTags: (result) => {
        return result
          ? [
              { type: 'clients', id: 'LIST' },
              { type: 'users', id: 'LIST' },
            ]
          : [];
      },
    }),
    getApiKeysForClientId: build.query<AuthTokenType[], { id: string }>({
      query: ({ id }) => {
        return {
          url: `/auth/keys/client/${id}`,
          method: 'get',
        };
      },
      providesTags: (result, error, { id }) => [
        { type: 'clients', id: `apiKey:${id}` },
      ],
    }),
    getClientPrimaryPolicies: build.query<
      { items: PrimaryPolicyDataType[] },
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: `/clients/${id}/primary-policies`,
          method: 'get',
        };
      },
    }),
    createClientPCWToken: build.mutation<
      { pcw_urls: PWCUrl[]; expiration: string | null },
      {
        id: string;
        datetime: string | null;
      }
    >({
      query: ({ id, datetime }) => {
        return {
          url: `/clients/${id}/pcw-token`,
          method: 'post',
          data: {
            expiration: datetime,
          },
          apiVersion: 'api/internal',
        };
      },
    }),
    getClientPCWTokens: build.query<
      { pcw_urls: PWCUrl[]; expiration: string | null }[],
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: `/clients/${id}/pcw-token`,
          method: 'get',
          apiVersion: 'api/internal',
        };
      },
    }),
    updateClientPCWTokens: build.mutation<
      { expiration: string | null },
      {
        id: string;
        token_id: string;
        datetime: string | null;
      }
    >({
      query: ({ id, token_id, datetime }) => {
        return {
          url: `/clients/${id}/pcw-token/${token_id}`,
          method: 'patch',
          data: {
            expiration: datetime,
          },
          apiVersion: 'api/internal',
        };
      },
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useCreateClientMutation,
  useCreateClientUserMutation,
  useGetApiKeysForClientIdQuery,
  useGetClientPrimaryPoliciesQuery,
  useCreateClientPCWTokenMutation,
  useGetClientPCWTokensQuery,
  useUpdateClientPCWTokensMutation,
} = clientsApi;
