


interface Props {
  text?: string
}

const Translations = ({ text }: Props) => <>{text}</>;

export default Translations;
