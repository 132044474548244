// packages
import {
  PureAbility,
  AbilityBuilder,
  AbilityTuple,
  MatchConditions,
  AbilityClass,
} from '@casl/ability';

// types
import { LoggedInUserType } from 'src/context/types';

export type ACLObj = {
  action: Actions;
  subject: Subjects;
};
export type Actions = 'view';
export type AppAbility = PureAbility<AbilityTuple, MatchConditions>;
export type Subjects =
  | 'affiliates'
  | 'clients'
  | 'users'
  | 'claims'
  | 'notifications'
  | 'orders'
  | 'policies'
  | 'all';

const AppAbility = PureAbility as AbilityClass<AppAbility>;
const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can view everything and client can just visit ACL page
 */

// Documentation: https://casl.js.org/v4/en/package/casl-react
// https://pixinvent.com/demo/materialize-mui-react-nextjs-admin-template/documentation/guide/development/access-control.html#how-to-remove-acl

const defineRulesFor = (user: LoggedInUserType) => {
  const { can, rules } = new AbilityBuilder(AppAbility);
  const { superuser, role } = user;

  if (superuser || role?.admin) {
    can('view', 'all');
    can('manage', 'all');
  } else if (role?.permissions) {
    const generateRule = (verb: string, permission: string) => {
      if (permission.startsWith(`can_${verb}_`))
        can(verb, permission.substring(`can_${verb}_`.length));
    };

    // Iterate through all permissions and inject them into CASL
    role.permissions.forEach((permission) => {
      generateRule('view', permission);
      generateRule('manage', permission);
    });

    // users can view and manage their own profiles
    const permissionExceptions = ['can_view_users', 'can_manage_users'];
    permissionExceptions.forEach((exception) => {
      if (!role.permissions.includes(exception)) {
        const splitException = exception.split('_');
        can<LoggedInUserType>(
          splitException[1],
          splitException[2],
          ({ id }) => id === user.id
        );
      }
    });
  }

  return rules;
};

export const buildAbilityFor = (user: LoggedInUserType): AppAbility =>
  new AppAbility(defineRulesFor(user), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: (object) => object!.type,
    conditionsMatcher: lambdaMatcher,
  });

export const defaultACLObj: ACLObj = {
  action: 'view',
  subject: 'all',
};

export default defineRulesFor;
