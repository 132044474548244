// ** React Import
import { ReactNode, useEffect } from 'react';
import {
  useTheme
} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext';
import Box from '@mui/material/Box';

interface Props {
  disableCollapse?: boolean;
  settings: Settings;
  navHover: boolean;
  menuLockedIcon?: ReactNode;
  menuUnlockedIcon?: ReactNode;
  saveSettings: (values: Settings) => void;
  verticalNavMenuBranding?: (props?: any) => ReactNode;
}

const CollapsedNavigationButton = (props: Props) => {
  // ** Props
  const {
    settings,
    navHover,
    saveSettings,
    menuLockedIcon: userMenuLockedIcon,
    menuUnlockedIcon: userMenuUnlockedIcon,
    disableCollapse = false
  } = props;

  const theme = useTheme();
  const {
    skin, direction,
    navCollapsed } = settings;

  useEffect(() => {
    if (disableCollapse && navCollapsed) {
      // force navigation back to expanded if it's disabled 
      saveSettings({ ...settings, navCollapsed: false });
    }
  }, [disableCollapse, navCollapsed, saveSettings, settings]);

  const svgRotationDeg = () => {
    if (navCollapsed) {
      if (direction === 'rtl') {
        if (navHover) {
          return 0;
        }

        return 180;
      }
      if (navHover) {
        return 180;
      }

      return 0;
    }
    if (direction === 'rtl') {
      return 180;
    }

    return 0;
  };

  const svgFillSecondary = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return `rgba(${theme.palette.customColors.dark}, 0.68)`;
    }
    if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return `rgba(${theme.palette.customColors.light}, 0.68)`;
    }

    return theme.palette.text.secondary;
  };
  const svgFillDisabled = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return `rgba(${theme.palette.customColors.dark}, 0.38)`;
    }
    if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return `rgba(${theme.palette.customColors.light}, 0.38)`;
    }

    return theme.palette.text.disabled;
  };

  return (!disableCollapse ?
    <IconButton
      disableRipple
      disableFocusRipple
      onClick={() =>
        saveSettings({ ...settings, navCollapsed: !navCollapsed })
      }
      sx={{
        p: 0,
        color: 'text.primary',
        backgroundColor: 'transparent !important',
      }}
    >
      {userMenuLockedIcon && userMenuUnlockedIcon ? (
        navCollapsed ? (
          userMenuUnlockedIcon
        ) : (
          userMenuLockedIcon
        )
      ) : (
        <Box
          width={22}
          fill='none'
          height={22}
          component='svg'
          viewBox='0 0 22 22'
          xmlns='http://www.w3.org/2000/svg'
          sx={{
            transform: `rotate(${svgRotationDeg()}deg)`,
            transition: 'transform .25s ease-in-out .35s',
          }}
        >
          <path
            fill={svgFillSecondary()}
            d='M11.4854 4.88844C11.0082 4.41121 10.2344 4.41121 9.75716 4.88844L4.51029 10.1353C4.03299 10.6126 4.03299 11.3865 4.51029 11.8638L9.75716 17.1107C10.2344 17.5879 11.0082 17.5879 11.4854 17.1107C11.9626 16.6334 11.9626 15.8597 11.4854 15.3824L7.96674 11.8638C7.48943 11.3865 7.48943 10.6126 7.96674 10.1353L11.4854 6.61667C11.9626 6.13943 11.9626 5.36568 11.4854 4.88844Z'
          />
          <path
            fill={svgFillDisabled()}
            d='M15.8683 4.88844L10.6214 10.1353C10.1441 10.6126 10.1441 11.3865 10.6214 11.8638L15.8683 17.1107C16.3455 17.5879 17.1193 17.5879 17.5965 17.1107C18.0737 16.6334 18.0737 15.8597 17.5965 15.3824L14.0779 11.8638C13.6005 11.3865 13.6005 10.6126 14.0779 10.1353L17.5965 6.61667C18.0737 6.13943 18.0737 5.36568 17.5965 4.88844C17.1193 4.41121 16.3455 4.41121 15.8683 4.88844Z'
          />
        </Box>
      )}
    </IconButton> : null
  );
};

export default CollapsedNavigationButton;