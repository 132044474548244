import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertColor } from '@mui/material/Alert';

export type AppAlert = {
  actionLink?: string;
  actionMessage?: string;
  message: string;
  onCloseCb?: () => any;
  timeout?: number;
  type?: AlertColor | undefined;
};

export type AlertState = {
  alert: AppAlert | null;
};

const initialState: AlertState = {
  alert: null,
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    pushMessageAlert: (state, action: PayloadAction<AppAlert>) => {
      const {
        actionLink,
        actionMessage,
        message,
        onCloseCb,
        timeout,
        type = 'error',
      } = action.payload;
      state.alert = {
        actionLink,
        actionMessage,
        message,
        onCloseCb,
        timeout,
        type,
      };
    },
    clearMessages: (state) => {
      state.alert = null;
    },
  },
});

export const { pushMessageAlert, clearMessages } = alertSlice.actions;

export default alertSlice.reducer;
