// ** React Imports
import { ReactNode, useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

// ** Next Imports
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';

// ** Store Imports
import { store } from 'src/store';
import { Provider } from 'react-redux';

// ** Loader Import
import NProgress from 'nprogress';

// ** Emotion Imports
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/cache';

// ** Config Imports
import themeConfig from 'src/configs/themeConfig';

// ** Fake-DB Import
import 'src/@fake-db';

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout';
import AclGuard from 'src/@core/components/auth/AclGuard';
import ThemeComponent from 'src/@core/theme/ThemeComponent';
import AuthGuard from 'src/@core/components/auth/AuthGuard';
import GuestGuard from 'src/@core/components/auth/GuestGuard';
import WindowWrapper from 'src/@core/components/window-wrapper';
import AppSnackbar from 'src/@core/components/snackbar';

// ** Spinner Import
import Spinner from 'src/@core/components/spinner';

// ** Contexts
import { AuthProvider } from 'src/context/AuthContext';
import {
  SettingsConsumer,
  SettingsProvider,
} from 'src/@core/context/settingsContext';

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/style';

// ** Prismjs Styles
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** Global css styles
import '../../styles/globals.css';
import '@fontsource/material-icons';

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

type GuardProps = {
  authGuard: boolean;
  guestGuard: boolean;
  children: ReactNode;
};

const { publicRuntimeConfig } = getConfig();
const version = publicRuntimeConfig?.version;

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'client-dashboard',
  env: process.env.NEXT_PUBLIC_NODE_ENV,
  version: version,
  sampleRate: Number(process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE),
  sessionReplaySampleRate: Number(
    process.env.NEXT_PUBLIC_DATADOG_REPLAY_SAMPLE_RATE
  ),
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});
datadogRum.startSessionReplayRecording();

if (process.env.NEXT_PUBLIC_ENABLE_ERROR === 'true') {
  throw new Error('testing DataDog error handling source map');
}

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });
  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });
  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });
}

const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
  if (guestGuard)
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>;

  if (!guestGuard && !authGuard) return <>{children}</>;

  return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
};

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  // Variables
  const getLayout =
    Component.getLayout ?? ((page) => <UserLayout>{page}</UserLayout>);
  const setConfig = Component.setConfig ?? undefined;

  const authGuard = Component.authGuard ?? true;
  const guestGuard = Component.guestGuard ?? false;

  const aclAbilities = Component.acl;
  const router = useRouter();

  // Addresses bug dealing with refreshing webpage when viewing a nested route.
  useEffect(() => {
    if (!router.isReady) return;

    const isRedirect =
      (router.pathname === '/' || router.pathname === '/dashboard/') &&
      router.asPath !== router.pathname;

    // Uses local storage to ensure we don't get stuck in infinite loop
    if (sessionStorage.getItem('hasRedirected')) {
      sessionStorage.removeItem('hasRedirected');

      // Redirects to /404 is route does not exist
      if (isRedirect) router.replace('/404');
    } else if (isRedirect) {
      sessionStorage.setItem('hasRedirected', 'true');
      router.replace(router.asPath);
    }
  }, [router]);

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{`${themeConfig.templateName}`}</title>
          <meta name='description' content={`${themeConfig.templateName}`} />
          <meta name='keywords' content='Protecht Client Dashboard' />
          <meta name='viewport' content='initial-scale=1, width=device-width' />
        </Head>

        <AuthProvider>
          <SettingsProvider
            {...(setConfig ? { pageSettings: setConfig() } : {})}
          >
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeComponent settings={settings}>
                  <WindowWrapper>
                    <Guard authGuard={authGuard} guestGuard={guestGuard}>
                      <AclGuard
                        aclAbilities={aclAbilities}
                        guestGuard={guestGuard}
                      >
                        {getLayout(<Component {...pageProps} />)}
                      </AclGuard>
                    </Guard>
                  </WindowWrapper>
                  <AppSnackbar />
                </ThemeComponent>
              )}
            </SettingsConsumer>
          </SettingsProvider>
        </AuthProvider>
      </CacheProvider>
    </Provider>
  );
};

export default App;
