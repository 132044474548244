// ** MUI Imports
import { Theme } from '@mui/material/styles';

const select = (theme: Theme) => ({
  MuiSelect: {
    styleOverrides: {
      root: {
        minWidth: '6rem !important',
      },
      select: {
        '&.MuiTablePagination-select': {
          minWidth: '1.5rem !important',
        },
        '&.Mui-disabled ~ .MuiOutlinedInput-notchedOutline': {
          borderColor: `rgba(${theme.palette.customColors.main}, 0.22)`,
        },
      },
    },
  },
});

export default select;
