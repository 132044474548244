// ** React Imports
import { ReactNode } from 'react';

// ** Next Import
import Link from 'next/link';

// ** MUI Components
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout';

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw',
  },
}));

const Error401 = () => (
  <Box className='content-center'>
    <Box
      sx={{
        p: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <BoxWrapper>
        <Typography variant='h1' sx={{ mb: 2.5 }}>
          401
        </Typography>
        <Typography
          variant='h5'
          sx={{ mb: 2.5, fontSize: '1.5rem !important' }}
        >
          You are not authorized! 🔐
        </Typography>
        <Typography variant='body2'>
          You don&prime;t have permission to access this page.
        </Typography>
      </BoxWrapper>
      <Link passHref href='/' legacyBehavior>
        <Button component='a' variant='contained' sx={{ px: 5.5, mt: 2 }}>
          Back to Home
        </Button>
      </Link>
    </Box>
  </Box>
);

Error401.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

export default Error401;
