// packages
import axios from 'axios';

// utisl
import { apiVersion as defaultApiVersion } from 'src/configs/globalVariable';

// types
import type { AxiosRequestConfig, AxiosError } from 'axios';
import type { UnionOverrideKeys } from 'src/context/types';

axios.defaults.withCredentials = true;

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL
    ? `${process.env.NEXT_PUBLIC_API_BASE_URL}`
    : '',
});

export const createAxiosBaseQuery =
  () =>
  async ({
    url,
    method = 'get',
    data,
    params,
    apiVersion = defaultApiVersion,
  }: UnionOverrideKeys<
    AxiosRequestConfig,
    { url: string; apiVersion?: string }
  >) => {
    try {
      let result;
      switch (method.toLowerCase()) {
        case 'get':
          result = await axiosInstance.get(`/${apiVersion}${url}`, {
            data,
            params,
          });
          break;
        case 'post':
          result = await axiosInstance.post(
            `/${apiVersion}${url}`,
            { ...data },
            { params }
          );
          break;
        case 'patch':
          result = await axiosInstance.patch(
            `/${apiVersion}${url}`,
            { ...data },
            { params }
          );
          break;
        case 'put':
          result = await axiosInstance.put(
            `/${apiVersion}${url}`,
            { ...data },
            { params }
          );
          break;
        case 'delete':
          result = await axiosInstance.delete(`/${apiVersion}${url}`, {
            data,
            params,
          });
          break;
      }

      return { data: result?.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
