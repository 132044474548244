import mock from './mock';

// import './users';
// import './orders';
// import './claims';
// import './affiliates';
// import './clients';
// import './notifications';
// import './auth';
// import './policies';

mock.onAny().passThrough();
