import {
  AffiliateDataType,
  UserMutationDataType,
  PrimaryPolicyDataType,
  UserDataType,
} from 'src/context/types';
import { coreApi } from 'src/store';

// https://redux-toolkit.js.org/rtk-query/overview
export const affiliatesApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getAffiliates: build.query<
      { items: AffiliateDataType[]; count: number },
      {
        params?: {
          page: number;
          pageSize?: number;
          sortModel: { field?: string; sort?: string };
          affiliate?: string;
          searchTerm?: string;
        };
      }
    >({
      query: ({ params }) => {
        const parameters =
          params &&
          ({
            offset: params.page * (params.pageSize ?? 0),
            sort: params.sortModel.sort
              ? `${params.sortModel.sort === 'desc' ? '-' : ''}${
                  params.sortModel.field
                }`
              : undefined,
            affiliate: params.affiliate || undefined,
            q: params.searchTerm || undefined,
          } as any);
        if (parameters && params.pageSize !== 0)
          parameters.limit = params.pageSize;

        return {
          url: `/affiliates`,
          method: 'get',
          ...{
            params: parameters,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(
                ({ id }) => ({ type: 'affiliates', id } as const)
              ),
              { type: 'affiliates', id: 'LIST' },
            ]
          : [],
    }),
    createAffiliateUser: build.mutation<UserDataType, UserMutationDataType>({
      query: ({ id, ...userData }) => ({
        url: `/affiliates/${id}/create-user`,
        method: 'post',
        data: userData,
      }),
      invalidatesTags: (result) => {
        return result
          ? [
              { type: 'affiliates', id: 'LIST' },
              { type: 'users', id: 'LIST' },
            ]
          : [];
      },
    }),
    getAffiliateById: build.query<AffiliateDataType, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/affiliates/${id}`,
          method: 'get',
        };
      },
      providesTags: (result, error, { id }) => [{ type: 'affiliates', id }],
    }),
    getAffiliatePrimaryPolicies: build.query<
      { items: PrimaryPolicyDataType[] },
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: `/affiliates/${id}/primary-policies`,
          method: 'get',
        };
      },
    }),
    getSubAffiliates: build.query<
      { items: AffiliateDataType[] },
      {
        params: {
          id: string;
          page: number;
          pageSize: number;
          sortModel: { field?: string; sort?: string };
          searchTerm: string;
        };
      }
    >({
      query: ({ params }) => {
        return {
          url: `/affiliates/${params.id}/sub-affiliates`,
          method: 'get',
          ...(params && {
            params: {
              limit: params.pageSize,
              offset: params.page * params.pageSize,
              sort: params.sortModel.sort
                ? `${params.sortModel.sort === 'desc' ? '-' : ''}${
                    params.sortModel.field
                  }`
                : undefined,
              q: params.searchTerm || undefined,
            },
          }),
        };
      },
      providesTags: (result) =>
        result ? [{ type: 'sub-affiliates', id: 'LIST' }] : [],
    }),
  }),
});

export const {
  useGetAffiliatesQuery,
  useLazyGetAffiliatesQuery,
  useCreateAffiliateUserMutation,
  useGetAffiliateByIdQuery,
  useGetAffiliatePrimaryPoliciesQuery,
  useGetSubAffiliatesQuery,
} = affiliatesApi;
