import {
  UserDataType,
  UserDeleteDataType,
  UserDeleteMutationDataType,
  UserMutationDataType,
} from 'src/context/types';
import { coreApi } from 'src/store';

// https://redux-toolkit.js.org/rtk-query/overview
export const usersApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getUsers: build.query<
      { items: UserDataType[] },
      {
        affiliate?: string;
        client?: string;
        page: number;
        pageSize: number;
        sortModel: {
          field?: string;
          sort?: string;
          secondField?: string;
          secondSort?: string;
        };
        searchTerm?: string;
      }
    >({
      query: ({ affiliate, client, page, pageSize, sortModel, searchTerm }) => {
        return sortModel.secondField
          ? {
              url: '/users',
              method: 'get',
              params: {
                affiliate,
                client,
                limit: pageSize,
                offset: page * pageSize,
                sort: sortModel.sort
                  ? `${sortModel.sort === 'desc' ? '-' : ''}${
                      sortModel.field
                    },${sortModel.secondSort === 'desc' ? '-' : ''}${
                      sortModel.secondField
                    }`
                  : undefined,

                q: searchTerm || undefined,
              },
            }
          : {
              url: '/users',
              method: 'get',
              params: {
                affiliate,
                client,
                limit: pageSize,
                offset: page * pageSize,
                sort: sortModel.sort
                  ? `${sortModel.sort === 'desc' ? '-' : ''}${sortModel.field}`
                  : undefined,
                q: searchTerm || undefined,
              },
            };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'users', id } as const)),
              { type: 'users', id: 'LIST' },
            ]
          : [{ type: 'users', id: 'LIST' }],
    }),
    getUserById: build.query<UserDataType, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/users/${id}`,
          method: 'get',
        };
      },
      providesTags: (result, error, { id }) => [{ type: 'users', id }],
    }),
    deleteUser: build.mutation<UserDeleteDataType, UserDeleteMutationDataType>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => [{ type: 'users', id: 'LIST' }],
    }),
    updateUser: build.mutation<
      UserDataType,
      Omit<UserMutationDataType, 'password'>
    >({
      query: ({ id, ...data }) => ({
        url: `/users/${id}`,
        method: 'post',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'users', id: 'LIST' },
              { type: 'users', id: result.id },
            ]
          : [],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi;
