// react
import {
  createContext,
  useCallback,
  useEffect,
  useState,
  ReactNode,
} from 'react';

// next
import { useRouter } from 'next/router';

// redux
import { useDispatch } from 'react-redux';
import { coreApi } from 'src/store';
import { pushMessageAlert } from 'src/store/slices/alert';

// hooks
import { useInterval } from 'src/@core/hooks/useInterval';

// utils
import authConfig from 'src/configs/auth';
import { axiosInstance } from 'src/store/baseAPI';
import { decodeJWTPayload } from 'src/@core/utils/auth';

// types
import {
  AuthValuesType,
  ErrCallbackType,
  LoggedInUserType,
  LoginParams,
  ProductDataType,
  ResendMfaTokenParams,
  ResetParams,
  SubmitMfaTokenParams,
} from 'src/context/types';
import type { AxiosError } from 'axios';

type Props = {
  children: ReactNode;
};

// constants
const defaultProvider: AuthValuesType = {
  activate: () => Promise.resolve(),
  email: '',
  forgot: () => Promise.resolve(),
  hasAccessToken: false,
  isInitialized: false,
  isPasswordExpiringWarning: false,
  isPasswordOtp: false,
  loading: true,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  password: '',
  passwordChangeToken: null,
  products: [],
  resendMfaToken: () => Promise.resolve(),
  reset: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setHasAccessToken: () => null,
  setIsInitialized: () => Boolean,
  setIsPasswordExpiringWarning: () => Boolean,
  setIsPasswordOtp: () => Boolean,
  setLoading: () => Boolean,
  setPasswordChangeToken: () => null,
  setProducts: () => [],
  setUser: () => null,
  submitMfaToken: () => Promise.resolve(),
  user: null,
};

const AuthContext = createContext(defaultProvider);

const guestRoutes = [
  '/activate',
  '/forgot-password',
  '/forgot-password/activate',
];

const clearAuthStorage = () => {
  sessionStorage.removeItem(authConfig.storageTempUserDataKeyName);
  sessionStorage.removeItem(authConfig.storageTempResetPasswordUserDataKeyName);
  localStorage.removeItem(authConfig.storageUserDataKeyName);
};

const AuthProvider = ({ children }: Props) => {
  // state
  const [email, setEmail] = useState('');
  const [hasAccessToken, setHasAccessToken] = useState(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(
    defaultProvider.isInitialized
  );
  const [isPasswordExpiringWarning, setIsPasswordExpiringWarning] =
    useState(false);
  const [isPasswordOtp, setIsPasswordOtp] = useState(false);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
  const [password, setPassword] = useState('');
  const [passwordChangeToken, setPasswordChangeToken] = useState(null);
  const [products, setProducts] = useState<ProductDataType[]>(
    defaultProvider.products
  );
  const [user, setUser] = useState<LoggedInUserType | null>(
    defaultProvider.user
  );

  // hooks
  const dispatch = useDispatch();
  const router = useRouter();

  const logout = useCallback(async () => {
    try {
      setLoading(true);

      // reset context
      resetAuthContext();

      // remove any values from local storage
      clearAuthStorage();

      // clear the auth cookie
      await axiosInstance.post(authConfig.logoutEndpoint);

      dispatch(coreApi.util.resetApiState());

      router.push('/login/');
    } catch (error) {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2 * 1000);
    }
  }, [dispatch, router]);

  const resetAuthContext = () => {
    // reset context
    setHasAccessToken(false);
    setEmail('');
    setUser(null);
    setIsPasswordOtp(false);
    setIsPasswordExpiringWarning(false);
    setPassword('');
    setPasswordChangeToken(null);
    setProducts([]);
  };

  useEffect(() => {
    const initAuth = async () => {
      if (isInitialized) return;

      setIsInitialized(true);

      // if the initially loaded route might cause conflicts with currently persisted
      // auth state, clear out local storage and break out of initializing auth
      if (guestRoutes.includes(router.pathname)) {
        clearAuthStorage();

        setLoading(false);

        return;
      }

      // make sure userData is removed from local storage if token is not found to prevent UI from hanging due to AuthGuard not resolving
      if (!hasAccessToken)
        localStorage.removeItem(authConfig.storageUserDataKeyName);

      if (sessionStorage.getItem(authConfig.storageTempUserDataKeyName)) {
        setLoading(false);

        localStorage.removeItem(authConfig.storageUserDataKeyName);

        router.replace('/activate/');

        return;
      }

      if (
        sessionStorage.getItem(
          authConfig.storageTempResetPasswordUserDataKeyName
        )
      ) {
        setLoading(false);

        localStorage.removeItem(authConfig.storageUserDataKeyName);

        router.replace('/forgot-password/activate/');

        return;
      }

      setLoading(true);

      try {
        const meRes = await axiosInstance.get(authConfig.meEndpoint);

        const generatedUser = meRes.data;

        if (
          Boolean(generatedUser.is_password_otp) &&
          !guestRoutes.includes(router.pathname)
        ) {
          setLoading(false);

          router.replace('/reset-password/');

          return;
        }

        setUser(generatedUser);
        setProducts([...generatedUser.products]);

        localStorage.setItem(
          authConfig.storageUserDataKeyName,
          JSON.stringify(generatedUser)
        );
      } catch (error) {
        // reset context
        resetAuthContext();

        // remove any values from local storage
        clearAuthStorage();
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, [hasAccessToken, isInitialized, router, user]);

  useInterval(
    async () => {
      try {
        if (!hasAccessToken) return;

        // extend the access token - no action needed to be performed on success because the cookie is set server-side
        await axiosInstance.post(authConfig.extendAccessToken);
      } catch (error) {
        await logout();
      }
    },

    // Delay in milliseconds or null to stop it
    typeof window !== 'undefined' && hasAccessToken ? 30 * 60 * 1000 : null
  );

  const handleJWTResponse = async (jwt: string) => {
    const decodedJWTPayload = decodeJWTPayload(jwt);

    setHasAccessToken(true);

    if (Boolean(decodedJWTPayload?.is_password_otp)) {
      setIsPasswordOtp(true);

      router.replace('/reset-password/');

      return Promise.reject('Need to reset password');
    }

    // display a toast notification that redirects users to the reset-password page on click,
    // and preserves their choice in local storage if dismissed
    if (
      decodedJWTPayload?.is_password_expiring_warning &&
      !localStorage.getItem(authConfig.resetPasswordAlertKeyName)
    ) {
      setIsPasswordExpiringWarning(true);
      dispatch(
        pushMessageAlert({
          actionLink: '/reset-password/',
          actionMessage: 'Reset it now',
          message: 'Your current password will expire soon.',
          onCloseCb: () =>
            localStorage.setItem(authConfig.resetPasswordAlertKeyName, 'true'),
          timeout: 12000,
          type: 'warning',
        })
      );
    }

    const meRes = await axiosInstance.get(authConfig.meEndpoint);

    const { returnUrl } = router.query;

    const generatedUser = meRes.data;

    setUser(generatedUser);
    setProducts([...generatedUser.products]);

    localStorage.setItem(
      authConfig.storageUserDataKeyName,
      JSON.stringify(generatedUser)
    );

    if (!returnUrl) {
      router.push('/dashboard/');

      return;
    }

    const formattedReturnUrl = Array.isArray(returnUrl)
      ? returnUrl.length
        ? returnUrl[0]
        : ''
      : returnUrl;

    if (!formattedReturnUrl) {
      router.push('/dashboard/');

      return;
    }

    sessionStorage.setItem('returnUrl', formattedReturnUrl);

    router.push('/');
  };

  const handleLogin = async (
    params: LoginParams,
    errorCallback?: ErrCallbackType
  ) => {
    try {
      clearAuthStorage();

      setEmail(params?.email);
      setPassword(params?.password);

      const loginRes = await axiosInstance.post(
        authConfig.loginEndpoint,
        params
      );

      if (!loginRes.data) return;

      if (loginRes.data?.['2fa']) return router.replace('/mfa/');

      setLoading(true);

      handleJWTResponse(loginRes.data);
    } catch (error) {
      const axiosError = error as AxiosError<{
        '2fa': boolean;
        error: string;
      }>;

      resetAuthContext();

      if (
        axiosError?.response?.data?.error?.includes(
          'Token generation cooldown period has not expired yet'
        )
      )
        return dispatch(
          pushMessageAlert({
            type: 'info',
            message:
              'Verification code cooldown period has not expired yet. Please wait before requesting a new code.',
          })
        );

      if (errorCallback) errorCallback(error as any);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2 * 1000);
    }
  };

  const activate = async (params: {
    id: string;
    resetPassword?: boolean;
    token: string;
  }) => {
    try {
      const activateRes = await axiosInstance.get(
        authConfig.activateEndpoint(params.id, params.token)
      );

      const jwt = decodeJWTPayload(activateRes.data);

      if (jwt?.password_change_token)
        setPasswordChangeToken(jwt?.password_change_token ?? null);

      if (jwt?.is_password_otp) setIsPasswordOtp(true);

      if (jwt?.is_password_expiring_warning) setIsPasswordExpiringWarning(true);

      const meRes = await axiosInstance.get(authConfig.meEndpoint);

      const generatedUser = meRes.data;

      setUser(generatedUser);
      setProducts([...generatedUser.products]);

      const key = params?.resetPassword
        ? authConfig.storageTempResetPasswordUserDataKeyName
        : authConfig.storageTempUserDataKeyName;

      sessionStorage.setItem(key, JSON.stringify(generatedUser));
    } catch (error) {
      resetAuthContext();

      router.push('/login/');
    }
  };

  const handleForgotPassword = (params: { email: string }) => {
    axiosInstance.post(authConfig.forgotEndpoint, params).then(() => {
      dispatch(
        pushMessageAlert({
          type: 'success',
          message:
            'Thank you! Instructions to reset your password have been sent to the email provided.',
        })
      );
      router.push('/login/');
    });
  };

  const resendMfaToken = async (params: ResendMfaTokenParams) => {
    try {
      await axiosInstance.post(authConfig.resendMfaTokenEndpoint, params);

      dispatch(
        pushMessageAlert({
          type: 'success',
          message: 'A new verification code has been sent to your email.',
        })
      );
    } catch (error) {
      const axiosError = error as AxiosError<{
        '2fa': boolean;
        error: string;
      }>;

      if (
        axiosError?.response?.data?.error?.includes(
          'Token generation cooldown period has not expired yet'
        )
      )
        return dispatch(
          pushMessageAlert({
            type: 'info',
            message:
              'Verification code cooldown period has not expired yet. Please wait before requesting a new code.',
          })
        );

      return dispatch(
        pushMessageAlert({
          type: 'error',
          message:
            axiosError?.response?.data?.error ??
            'A new verification code could not be sent.',
        })
      );
    }
  };

  const resetPassword = (params: ResetParams) =>
    axiosInstance.post(authConfig.resetEndpoint, params);

  const handleResetPassword = (params: ResetParams) => {
    resetPassword(params)
      .then(() => {
        dispatch(
          pushMessageAlert({
            type: 'success',
            message: 'Successfully changed password',
          })
        );

        clearAuthStorage();
        localStorage.removeItem(authConfig.resetPasswordAlertKeyName);

        router.push('/login/');

        setIsPasswordOtp(false);
        setIsPasswordExpiringWarning(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const submitMfaToken = async (params: SubmitMfaTokenParams) => {
    try {
      const loginRes = await axiosInstance.post(
        authConfig.loginEndpoint,
        params
      );

      setLoading(true);

      handleJWTResponse(loginRes.data);
    } catch (error) {
      const axiosError = error as AxiosError<{
        '2fa': boolean;
        error: string;
      }>;

      if (
        axiosError?.response?.data?.error
          ?.toLowerCase()
          ?.includes('invalid otp token')
      )
        return dispatch(
          pushMessageAlert({
            type: 'error',
            message:
              "The code you provided isn't valid. Please try again or request a new code.",
          })
        );

      return dispatch(
        pushMessageAlert({
          type: 'error',
          message:
            axiosError?.response?.data?.error ??
            'Something went wrong trying to verify this code.',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const values = {
    activate,
    email,
    forgot: handleForgotPassword,
    hasAccessToken,
    isInitialized,
    isPasswordExpiringWarning,
    isPasswordOtp,
    loading,
    login: async (params: LoginParams, errorCallback?: ErrCallbackType) => {
      setLoading(true);
      await handleLogin(params, errorCallback);
    },
    logout,
    password,
    passwordChangeToken,
    products,
    resendMfaToken,
    reset: (params: ResetParams) => {
      setLoading(true);
      handleResetPassword(params);
    },
    resetPassword,
    setHasAccessToken,
    setIsInitialized,
    setIsPasswordExpiringWarning,
    setIsPasswordOtp,
    setLoading,
    setPasswordChangeToken,
    setProducts,
    setUser,
    submitMfaToken,
    user,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
